export function NotFound({type = 'page'}: {type?: string}) {

  return (
    <div className={' mt-[20%] flex flex-col text-violet justify-center items-center gap-[25px] desktop:px-[30%] laptop:px-[30%] tablet:px-[25%] mobile:px-[10%] old:px-[10%] text-center'}>
      <span className={'font-playfair desktop:text-[270px] tablet:text-[270px] laptop: text-[140px] mobile:text-[77px] old:text-[77px]'}>404</span>
      <span className={'font-playfair desktop:text-36 laptop:text-36 tablet:text-24 mobile:text-18 old:text-18'}>Oops, mi dispiace, ma sembra che quello che stai cercando non esista più!</span>
      <span className={'uppercase font-graphik desktop:text-16 laptop:text-16 tablet:text-14 mobile:text-12 old:text-12 px-[11%] mobile:px-[0] old:px-[0] text-center'}>
        Vai alla pagina&nbsp;
        <a className={'underline underline-offset-4'} href={'/'}>Home</a> o al catalogo per&nbsp;
        <a className={'underline underline-offset-4'} href={"/catalogo"}>continuare lo shopping</a>.
      </span>
    </div>
  );
}
